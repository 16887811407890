import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Share = (props) => {

  return (
    <>

      <div className="is-inner-page">

        <Layout>

        <SEO title="Início" />

        <div className="section">
          <div className="container">
            <div className="document-container" style={{ backgroundImage: `url(${require('../images/backgrounds/section-dots-bg.svg')})` }}>
              <div className="document-content">
                <h1 className="title">
                  Regras da Campanha
                </h1>
                <div className="v-space-lg"></div>
                <p className="text">

                  <strong>
                    Bem-vindo ao Portal “Atitudes do Bem” Malwee.
                  </strong>

                  <br/>
                  <br/>

                  Ao visitar ou utilizar este site, você visitante, obriga-se a ler e dar concordância a todos os termos abaixo.
                  <br/>
                  <br/>
                  Fundado em 1968 e com 5,5 mil colaboradores, o Grupo Malwee é uma das principais empresas de moda do Brasil e uma das mais modernas do mundo. Detentora das marcas Malwee, Scene, Enfim, Wee!, Malwee Kids, Carinhoso, Zig Zig Zaa e Malwee Liberta, estamos presentes em todo o país por meio de 24 mil pontos de vendas multimarcas e 82 lojas monomarca.
                  <br/>
                  <br/>
                  Ao completar 50 anos de fundação, o Grupo Malwee criou a campanha “Atitudes do Bem”, para mostrar e conscientizar o poder de pequenas atitudes e disseminar a prática, e este site é mais uma das ações da campanha, onde você, poderá contribuir com entidades de sua escolha através da doação do Grupo Malwee originada pela aquisição da peça em promoção.
                  <br/>
                  <br/>
                  Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:
                  <br/>
                  <br/>
                  Anti-Spam: Sistema que evita correspondências não desejadas, como publicidade em massa, pelo bloqueio de mensagens ou as movendo para pasta específica.
                  <br/>
                  <br/>
                  Conta de Acesso: Credencial de um visitante cadastrado que permite o acesso à área restrita e às funcionalidades exclusivas, que é definida pelo nome de usuário e senha.
                  <br/>
                  <br/>
                  Layout: Conjunto compreendido entre aparência, design e fluxos do Portal.
                  <br/>
                  <br/>
                  Link: Terminologia para endereço de internet.
                  <br/>
                  <br/>
                  Logs: Registros de atividades do visitante efetuadas no Portal.
                  <br/>
                  <br/>
                  GRUPO MALWEE: Denominação utilizada nesta Política de Privacidade para identificar a detentora e proprietária deste site, MALWEE MALHAS LTDA., pessoa jurídica de direito privado inscrita no CNPJ com o número 84.429.737/0001-14, com sede na cidade de Jaraguá do Sul, Estado de Santa Catarina, à Rua Bertha Weege nº 200, Bairro Barra do Rio Cerro sob o CEP 89260-900 e INSTITUTO MALWEE, pessoa jurídica de direito privado constituída sob a forma de associação civil para fins não econômicos, com sede na Rua Alvorada, 1289, 14º andar, sala 01, CEP 04550-004, Vila Olímpia, cidade e Estado de São Paulo.
                  <br/>
                  <br/>
                  Portal ATITUDES DO BEM: Designa o site de internet hospedado em atitudesdobem.com.br onde estarão hospedados os seus subdomínios.
                  <br/>
                  <br/>
                  Site: Denominação para páginas de internet.
                  <br/>
                  <br/>
                  Usuários: Pessoas que acessam ou interagem com as atividades oferecidas pelo Portal ATITUDES DO BEM.
                  <br/>
                  <br/>
                  Webmastering: Compreende a criação, programação, desenvolvimento, controle e disponibilidade de páginas de internet.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  1. Acesso e Restrições de funcionalidade
                  <br/>
                  <br/>
                  1.1. Este Portal é dedicado à Campanha ATITUDES DO BEM, que consiste na doação de R$ 30,00 reais pelo GRUPO MALWEE, a ONG de escolha do usuário que tenha efetuado a compra de uma peça de roupa do Grupo. 
                  <br/>
                  <br/>
                  1.2. Não é permitido aos usuários acessarem as áreas de programação de Portal, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de Webmastering, também não é autorizado realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das ferramentas de consulta deste Portal e de suas funcionalidades, ficando sujeito quem o fizer à legislação penal brasileira e obrigados a reparar os danos que causarem.
                  <br/>
                  <br/>
                  1.3. Ao usuário não é permitido modificar pacotes de dados enviados ao GRUPO MALWEE, seja pela alteração do endereço de IP de origem ou de retorno de dados ou pelo payload carregado, tal qual a aplicação de outras técnicas para mascarar, esconder, alterar ou falsear seu endereço de IP durante seu acesso ou tentativa(s) ou, de forma geral, interferir no funcionamento regular de conexão entre seu dispositivo e os servidores do GRUPO MALWEE.
                  <br/>
                  <br/>
                  1.3.1. Também, o usuário não deverá submeter conteúdos ao GRUPO MALWEE que contiverem programas ou scripts maliciosos, sob pena de suspensão ou cancelamento da conta de acesso, conforme disposto em 2.8.
                  <br/>
                  <br/>
                  1.4. Neste Portal é proibida a utilização, de aplicativos spider, ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo automatizado, tanto para realizar operações massificadas ou para quaisquer outras finalidades, sob aplicação da legislação penal brasileira e de reparar os danos que decorrerem desta utilização.
                  <br/>
                  <br/>
                  1.5. É ônus dos visitantes e usuários arcar com os meios necessários para navegar neste Portal, incluindo os requisitos para o acesso à Internet.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  2. Informações gerais sobre o site e seu funcionamento
                  <br/>
                  <br/>
                  2.1. Este Portal é apresentado ao público da web na maneira como está disponível, podendo passar por constantes aprimoramentos e atualizações, não ficando obrigado o GRUPO MALWEE a manter uma estrutura ou layout, se não por sua própria conveniência.
                  <br/>
                  <br/>
                  2.2. O GRUPO MALWEE  envida seus melhores esforços para a disponibilidade contínua e permanente do Portal, ficando sujeito, todavia, a eventos extraordinários, como desastres naturais, falhas ou colapsos nos sistemas centrais de comunicação e acesso à internet ou fatos de terceiro, que fogem de sua esfera de vigilância e responsabilidade.
                  <br/>
                  <br/>
                  2.3.  GRUPO MALWEE  não se responsabiliza por qualquer dano, prejuízo ou perda no equipamento dos usuários causados por falhas no sistema, no servidor ou na conexão à Internet, ainda que decorrentes de condutas de terceiros, inclusive por ações de softwares maliciosos como vírus, cavalos de tróia, e outros que possam, de algum modo, danificar o equipamento ou a conexão dos visitantes e usuários em decorrência do acesso, utilização ou navegação neste Portal, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos no mesmo.
                  <br/>
                  <br/>
                  2.4. Igualmente, o GRUPO MALWEE  não será responsável por danos emergentes ou lucros cessantes que decorrerem de indisponibilidade, erros, falhas, bugs e vulnerabilidades do portal, incluindo a funcionalidade da plataforma ou a integridade e disponibilidade dos dados submetidos pelos usuários.
                  <br/>
                  <br/>
                  2.6. o GRUPO MALWEE  não detém qualquer responsabilidade pela navegação dos usuários nos links externos contidos no Portal, sendo o dever deles a leitura dos Termos de Uso e Política de Privacidade do site acessado e agir conforme o determinado. Também serão os principais responsáveis caso sobrevier algum dano ou prejuízo, uma vez que a navegação segura cabe aos mesmos e que o Portal ATITUDES DO BEM apenas apontou o link cabendo ao interessado a visita, ou não, ao site.
                  <br/>
                  <br/>
                  2.7. O GRUPO MALWEE não se responsabiliza pelos atos praticados por usuários em seu Portal, devendo cada um ser imputado de acordo com a qualidade do uso da plataforma.
                  <br/>
                  <br/>
                  2.8. O GRUPO MALWEE  poderá suspender ou excluir as contas de acesso de seus usuários que descumprirem os presentes Termos de Uso, a qualquer tempo e sem a necessidade de aviso prévio, o que não dará direito a nenhuma indenização ou ressarcimento, sob qualquer pretexto.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  3. Comunicação do Portal com seus usuários
                  <br/>
                  <br/>
                  3.1. O GRUPO MALWEE poderá utilizar todos os canais de comunicação fornecidos e autorizados com seus usuários, nas suas mais diversas modalidades, seja e-mail, ligação telefônica ou envio por SMS.
                  <br/>
                  <br/>
                  3.1.1. A responsabilidade pelo recebimento dos comunicados é exclusiva dos usuários, por isso é indispensável que sempre forneçam os dados corretos e precisos, além de os manterem atualizados.
                  <br/>
                  <br/>
                  3.1.2. É igualmente de suas alçadas deixarem seus sistemas de anti-spam configurados de modo que não interfiram no recebimento dos comunicados e materiais do Portal ATITUDES DO BEM, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail em virtude desse bloqueio ou filtro similar.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  4. Privacidade dos visitantes e usuários no Portal
                  <br/>
                  <br/>
                  4.1. O Portal possui documento próprio, denominado Política de Privacidade, que regula o tratamento dado às informações de cadastro e demais informações e dados coletados.
                  <br/>
                  <br/>
                  4.2. A Política de Privacidade é parte integrante e inseparável dos Termos de Uso e Condições de Navegação deste Portal, e pode ser acessada no link encontrado em seu rodapé.
                  <br/>
                  <br/>
                  4.3. Caso alguma disposição da Política de Privacidade conflitar com qualquer outra do presente documento, deverá prevalecer o descrito na norma mais específica.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  5. Obrigações do Portal
                  <br/>
                  <br/>
                  5.1. O GRUPO MALWEE se obriga com os visitantes e seus usuários a:
                  <br/>
                  <br/>
                  5.1.1. Manter o ambiente digital seguro, tal qual suas operações de gerenciamento e dados registrados para tanto, salvo por ato destrutivo de terceiro que vá além dos esforços empenhados e do estado atual da tecnologia, hipótese que não se responsabilizará por danos oriundos dessa prática danosa.
                  <br/>
                  <br/>
                  5.1.2. Preservar a funcionalidade do site, com links não quebrados, utilizando layout que respeita a usabilidade, segurança e navegabilidade, facilitando a navegação sempre que possível e garantindo um ambiente confiável para processar suas operações.
                  <br/>
                  <br/>
                  5.1.3. Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas.
                  <br/>
                  <br/>
                  5.1.4. Garantir, por meio do estado da técnica disponível, o sigilo e confidencialidade dos dados inseridos nas funcionalidades oferecidas em seu Portal, somente sendo acessíveis a quem o usuário declinar, além de si próprio.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  6. Obrigações do usuário
                  <br/>
                  <br/>
                  6.1. Os usuários se obrigam a realizar uma navegação com retidão ética, sempre respeitando as condições que regem a utilização do Portal.
                  <br/>
                  <br/>
                  6.2. Ao fornecer dados e informações, os usuários se obrigam a fazê-lo sempre com compromisso de veracidade e autenticidade, sob pena da aplicação das penas da lei, de indenizar a quem causar dano e de ter a conta de acesso do presente Portal excluída.
                  <br/>
                  <br/>
                  6.3. Na incidência de danos ao Portal ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, não devendo o GRUPO MALWEE responder por tais prejuízos.
                  <br/>
                  <br/>
                  6.4. Os usuários devem utilizar os recursos do presente Portal para as finalidades que foi constituído, sob pena da aplicação da lei, e de indenizarem a quem causarem dano e de terem a conta de acesso do presente Portal excluída.
                  <br/>
                  <br/>
                  6.5. Também, os usuários devem manter o sigilo dos dados de sua conta de acesso, sua senha e demais dispositivos de autenticação em relação a terceiros, não os divulgando ou compartilhando com quem quer que seja, sob pena da exclusão da conta e de ter de ressarcir os danos que sobrevierem dessa divulgação indevida.
                  <br/>
                  <br/>
                  6.5.1. Caso o usuário suspeite que a confidencialidade de sua senha foi quebrada ou que houve o comprometimento de algum dispositivo de autenticação, este deverá proceder sua troca ou atualização o mais rápido possível. Se não conseguir acessar a Internet para obter suporte, deverá buscar atendimento dentre os canais disponíveis.
                  <br/>
                  <br/>
                  6.6. Reconhecer como válidos, legítimos e eficazes todos os atos jurídicos praticados mediante sua conta de acesso no portal ATITUDES DO BEM, não podendo alegar repúdio quanto à forma no momento da contratação do presente serviço ou das operações realizadas posteriormente.
                  <br/>
                  <br/>
                  6.7. O usuário se obriga em defender, indenizar e manter o GRUPO MALWEE e seus colaboradores livres de dano de qualquer reivindicação ou solicitação decorrentes do mau uso da plataforma ou da violação aos presentes Termos de Uso, especialmente na esfera judicial.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  7. Direitos autorais e propriedade intelectual de Portal
                  <br/>
                  <br/>
                  7.1. Ao acessar este Portal, os usuários declaram que irão respeitar todos os direitos de propriedade intelectual e os decorrentes da proteção de marcas, patentes e/ou desenhos industriais, depositados ou registrados em nome do GRUPO MALWEE, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma, disponíveis no Portal. O simples acesso ao Portal não confere a estes qualquer direito ao uso dos nomes, títulos, palavras, frases, marcas, patentes, obras literárias, artísticas, lítero-musicais, imagens, dados e informações, dentre outras, que nele estejam ou estiveram disponíveis.
                  <br/>
                  <br/>
                  7.2. A reprodução dos conteúdos descritos anteriormente está proibida, salvo com prévia autorização por escrito do GRUPO MALWEE.
                  <br/>
                  <br/>
                  7.3. É permitido fazer somente o arquivo temporário deste Portal e de seu conteúdo, sendo vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que contrarie a realidade para o qual foi concebido, conforme definido neste documento. 
                  <br/>
                  <br/>
                  7.4. Qualquer outro tipo de utilização de material autorizado, inclusive para fins editoriais, comerciais ou publicitários, só poderá ser feito mediante prévio e expresso consentimento do GRUPO MALWEE. Os usuários estão cientes, através deste item, que o uso comercial não autorizado poderá incorrer em infrações cíveis e criminais, por estar infringindo a lei dos direitos autorais.
                  <br/>
                  <br/>
                  7.5. A autorização para utilização do material solicitado não poderá ser transferida a terceiros, mesmo que vinculados ao sujeito autorizado por alguma razão.
                  <br/>
                  <br/>
                  7.6. Qualquer utilização não contemplada na mencionada autorização será considerada como uma violação dos direitos de autor e sujeita às sanções cabíveis na Lei 9.610, de 19 de fevereiro de 1998, que protege os direitos autorais no Brasil.
                  <br/>
                  <br/>
                  7.7. A eventual retirada de qualquer conteúdo ou funcionalidade deste Portal, em decorrência de alguma reclamação, deverá ser sempre compreendida como uma demonstração de nossa intenção de evitar dissabores e, jamais, como reconhecimento de culpa ou de qualquer infração pelo GRUPO MALWEE a direito de terceiro.
                  <br/>
                  <br/>
                  7.8. Caso o visitante ou usuário enviar conteúdo para o GRUPO MALWEE por meio do Portal ATITUDES DO BEM ou pelos canais divulgados que diga respeito a ideias para novos produtos, melhorias nos produtos já existentes, campanhas publicitárias ou qualquer outra ação de marketing, elas deixarão de pertencer ao patrimônio imaterial do visitante ou do usuário, ingressando no patrimônio do GRUPO MALWEE nada podendo reclamar neste sentido, sobretudo recompensa pecuniária pelo que foi enviado ou revisão nos modelos usados.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  8. Das funcionalidades do Portal 
                  <br/>
                  <br/>
                  8.1. O Portal ATITUDES DO BEM, serve como plataforma que oferece ao usuário que tenha adquirido uma peça de roupa do GRUPO MALWEE a possibilidade de escolher uma entidade beneficente dentre as cadastradas no portal, para que o GRUPO MALWEE faça uma doação de R$ 30,00 (trinta reais), mas não se limitando a:
                  <br/>
                  Descrição da entidade beneficente;
                  <br/>
                  Parceiros apoiadores;
                  <br/>
                  Lojas com maiores ativações de cadastros;
                  <br/>
                  <br/>
                  8.2. O GRUPO MALWEE se vale de mecanismos de salvaguarda e proteção compatíveis com o estado atual da técnica para evitar perda de dados, mesmo em casos de incidentes ou desastres. No entanto, é o usuário quem deve tomar as cautelas necessárias, como a efetuação de backups periódicos dos dados inseridos para evitar prejuízos em caso de perda, inconsistência ou desastres que possam comprometer a base de dados do GRUPO MALWEE.
                  <br/>
                  <br/>
                  8.3. O GRUPO MALWEE se reserva no direito de não publicar ou excluir conteúdo enviados pelos usuários, a qualquer tempo, sem prévio aviso e nenhum direito a indenização a quem quer que seja, que contiverem:
                  <br/>
                  Ofensa à honra, imagem, reputação e dignidade de terceiros;
                  <br/>
                  Pornografia, pedofilia, e outras modalidades de satisfação sexual;
                  <br/>
                  Racismo ou discriminação de qualquer natureza;
                  <br/>
                  Bullying, Stalking ou qualquer outra espécie de constrangimento ilegal ou assédio;
                  <br/>
                  Manifesta violação a direito autoral ou direito de imagem;
                  <br/>
                  Utilização de marcas, símbolos, logotipos ou emblemas de terceiros;
                  <br/>
                  Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes, estupro, homicídio, estelionato, dentre outros;
                  <br/>
                  Uso da violência, disseminação de ódio ou qualquer outra forma de agressão ao bem estar físico de alguém;
                  <br/>
                  Erros ou suspeita de equívocos.
                  <br/>
                  <br/>
                  8.4. Os visitantes poderão se valer do canal de ‘Fale Conosco’ toda vez que presenciarem ou verificarem conteúdo impróprio no site.
                  <br/>
                  <br/>
                  8.4.1. A denúncia será sigilosa e preservará a identidade do visitante.
                  <br/>
                  <br/>
                  8.5. O usuário poderá excluir sua conta de acesso a qualquer tempo, sem necessidade de pagamento ou indenização ao GRUPO MALWEE. Recomenda-se que o usuário efetue a salvaguarda de seus dados antes de cancelamento da conta de acesso.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  9. Atendimento do Portal ATITUDES DO BEM
                  <br/>
                  <br/>
                  9.1. Caso os haja alguma dúvida sobre as condições estabelecidas neste termo ou qualquer documento do website, por favor,  envie um e-mail para sac@malwee.com.br . Será um prazer ajudar a esclarecer qualquer de suas dúvidas.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  10. Modificações destes Termos e Condições
                  <br/>
                  <br/>
                  10.1. Os presentes Termos de Uso e Condições de Navegação estão sujeitos a constante melhoria e aprimoramento. Assim, o GRUPO MALWEE se reserva o direito de modificar a qualquer momento, de forma unilateral, o presente documento e sua Política de Privacidade, que ficará disponível no seu site.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  11. Disposições Gerais
                  <br/>
                  <br/>
                  11.1. O usuário está de acordo que o GRUPO MALWEE poderá ceder os direitos e obrigações decorrentes destes Termos de Uso, total ou parcialmente, conforme sua conveniência e interesse, sem a necessidade de aviso prévio ou obrigação de indenização ou ressarcimento, sob qualquer pretexto.
                  <br/>
                  <br/>
                  11.2. A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.
                  <br/>
                  <br/>
                  11.3. Este Portal tem como base o horário oficial de Brasília.
                  <br/>
                  <br/>
                  11.4. Caso alguma disposição deste Termos de Uso seja considerada ilegítima por autoridade da localidade que o visitante ou usuário acessa o Portal, ou mantém vínculo, as demais condições permanecerão em pleno vigor e efeito até segunda ordem.
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  12. Lei aplicável e Jurisdição
                  <br/>
                  <br/>
                  12.1. Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca de Jaraguá do Sul no Estado de Santa Catarina para dirimir qualquer litígio, questão ou dúvida superveniente, com expressa renúncia de qualquer outro, por mais privilegiado que seja.
                </p>
              </div>
            </div>
          </div>
        </div>



      </Layout>

      </div>


     </>
  );

};

export default Share;
